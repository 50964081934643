<template>
  <el-scrollbar :style="{height:contentStyleObj}">

    <div class="big_box4">
      <div class="top_title3">
        <h4>加油站月销售油品汇总表</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <div class="cent">
        <div class="add" @click="handleAddItem(assetsArr.item,index)">
          <i class="iconfont icon-jiahao"></i>
        </div>
        <table class="content" cellspacing="0">
          <tr class="top_bg">
            <td colspan="16" class="center">加油站月销售油品汇总表纳税人信息</td>
          </tr>
          <tr >
            <td class="center bg_color" >法定代表人</td>
            <td colspan="2"> <el-input class="boxShadowNone" v-model="this.assetsArr.legalRepresentative"></el-input></td>
            <td class="center bg_color">联系电话</td>
            <td colspan="2">  <el-input class="boxShadowNone" v-model="this.assetsArr.legalRepresentativePhone"></el-input></td>
            <td class="center bg_color">填表人</td>
            <td colspan="2"> <el-input class="boxShadowNone" v-model="this.assetsArr.preparer"></el-input></td>
            <td class="center bg_color">填表日期</td>
            <td colspan="2"> <el-input class="boxShadowNone" v-model="this.assetsArr.fillingTime"></el-input></td>
            <td class="center bg_color">受理税务机关</td>
            <td colspan="3"> <el-input class="boxShadowNone" v-model="this.assetsArr.taxAcceptingAuthority"></el-input></td>
          </tr>
          <tr class="top_bg">
            <td colspan="16" class="center">加油站月销售油品汇总表</td>
          </tr>
          <tr class="top_bg">
            <td class="center" colspan="2">出油数量</td>
            <td class="center" colspan="8">应扣除油量</td>
            <td class="center" colspan="2">应税销售数量</td>
            <td class="center" colspan="4">销售金额</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:7%" rowspan="2">本月数</td>
            <td class="center" rowspan="2">累计数</td>
            <td class="center" colspan="4">本月数</td>
            <td class="center" colspan="4">累计数</td>
            <td class="center" rowspan="2">本月数</td>
            <td class="center" rowspan="2">累计数</td>
            <td class="center" colspan="2">通过加油机</td>
            <td class="center" colspan="2">不通过加油机</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:6%">自用</td>
            <td class="center" style="width:6%">倒库</td>
            <td class="center" style="width:6%">代储</td>
            <td class="center" style="width:6%">检测</td>
            <td class="center" style="width:6%">自用</td>
            <td class="center" style="width:6%">倒库</td>
            <td class="center" style="width:6%">代储</td>
            <td class="center" style="width:6%">检测</td>
            <td class="center" style="width:6%">本月数</td>
            <td class="center" style="width:6%">累计数</td>
            <td class="center" style="width:6%">本月数</td>
            <td class="center" style="width:6%">累计数</td>
          </tr>
          <tr v-for="(item, $index) in assetsArr.item" :key="$index" class="position_tr">
            <qzf-str-td style="width:7%" :disabled="false" v-model:amount="item.l1" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:7%" :disabled="false" v-model:amount="item.l2" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l4" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l6" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l7" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l8" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l9" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l10" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:7%" :disabled="false" v-model:amount="item.l11" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:7%" :disabled="false" v-model:amount="item.l12" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l13" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l14" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l15" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:6%" :disabled="false" v-model:amount="item.l16" @change="changeList()"></qzf-str-td>
            <div class="close" @click="handleDelItem(this.assetsArr.item,$index)">
              <i class="iconfont icon-shanchu"></i> 
            </div>
          </tr>
        </table>
      </div>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
        <el-button type="primary" @click="saveFb14()" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
        <qzf-button type="primary" @success="saveFb14()" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
      </div>
    </div>

    <div class="big_box4">
      <div class="top_title3">
        <h4>加油站月份加油信息明细表</h4>
      </div>
      <div class="cent">
        <div class="add2" @click="handleAddItem2(this.assetsArr2.item,index)">
          <i class="iconfont icon-jiahao"></i>
        </div>
        <table class="content" cellspacing="0">
          <tr >
            <td class="center bg_color"  style="width:20%">纳税人识别号（统一社会信用代码）</td>
            <td><el-input class="boxShadowNone" v-model="this.assetsArr2.taxpayerNumber"></el-input></td>
            <td class="center bg_color" style="width:20%">纳税人名称</td>
            <td colspan="2"><el-input class="boxShadowNone" v-model="this.assetsArr2.taxpayer"></el-input></td>

          </tr>
          <tr >
            <td class="center bg_color" >法定代表人</td>
            <td><el-input class="boxShadowNone" v-model="this.assetsArr2.legalRepresentative"></el-input></td>
            
            <td class="center bg_color" >联系电话</td>
            <td colspan="2"><el-input class="boxShadowNone" v-model="this.assetsArr2.legalRepresentativePhone"></el-input></td>
          </tr>
          <tr >
            <td class="center bg_color" >所属月份</td>
            <td><el-input class="boxShadowNone" v-model="this.assetsArr2.month"></el-input></td>
            
            <td class="center bg_color" >总枪数</td>
            <td colspan="2"><el-input class="boxShadowNone" v-model="this.assetsArr2.gunslinger"></el-input></td>

          </tr>
          <tr class="top_bg">
            <td class="center" style="width: 20%;">加油枪出厂编号</td>
            <td class="center" style="width: 30%;">油品型号</td>
            <td class="center" style="width: 20%;">月累计加油量</td>
            <td class="center" style="width: 14%;">月累计金额</td>
            <td class="center" style="width: 16%;">月应纳增值税额</td>
          </tr>
          <tr v-for="(item, $index) in this.assetsArr2.item" :key="$index" class="position_tr">
            <td><el-input class="boxShadowNone" v-model="item.l1" @change="changeList()"></el-input></td>
            <!-- <qzf-str-td v-model:amount="item.l1" @change="changeList()"></qzf-str-td> -->
            <!-- <qzf-str-td v-model:amount="item.l2" @change="changeList()"></qzf-str-td> -->
            <td><el-input class="boxShadowNone" v-model="item.l2"></el-input></td>
            <qzf-str-td v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
            <qzf-str-td v-model:amount="item.l4" @change="changeList()"></qzf-str-td>
            <qzf-str-td v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
            <div class="close" @click="handleDelItem2(this.assetsArr2.item,$index)">
              <i class="iconfont icon-shanchu"></i> 
            </div>
          </tr>
          <tr>
            <td class="center top_bg">合计</td>
            <td class="center">---</td>
            <td class="center">{{sumAmount}}</td>
            <td class="center">{{sumAmount2}}</td>
            <td class="center">{{sumAmount3}}</td>
          </tr>
          <!-- <tr >
            <td class="center top_bg">合计</td>
            <td class="center">---</td>
            <qzf-str-td v-model:amount="assetsArr2[0].zcyz" @change="changeList()"></qzf-str-td>
            <qzf-str-td v-model:amount="assetsArr2[0].zcyz" @change="changeList()"></qzf-str-td>
            <qzf-str-td v-model:amount="assetsArr2[0].zcyz" @change="changeList()"></qzf-str-td>
          </tr> -->
          <tr >
            <td class="center bg_color" >填表人</td>
            <td><el-input class="boxShadowNone" v-model="this.assetsArr2.preparer"></el-input></td>

            <td class="center bg_color" >填表日期</td>
            <td colspan="2"><el-input class="boxShadowNone" v-model="this.assetsArr2.fillingTime"></el-input></td>

          </tr>
          <tr >
            <td class="center bg_color" >受理人</td>
            <td><el-input class="boxShadowNone" v-model="this.assetsArr2.receiver"></el-input></td>

            <td class="center bg_color" >受理日期</td>
            <td colspan="2"><el-input class="boxShadowNone" v-model="this.assetsArr2.processingTime"></el-input></td>

          </tr>
          <tr >
            <td class="center bg_color" >受理税务机关</td>
            <td colspan="4" ><el-input class="boxShadowNone" v-model="this.assetsArr2.taxAcceptingAuthority"></el-input></td>

          </tr>
        </table>
      </div>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
        <el-button type="primary" @click="saveFb15()" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
        <qzf-button type="primary" @success="saveFb15()" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
      </div>
    </div>
  </el-scrollbar>

</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'fb1',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  computed: {
    //金额合计
    sumAmount() {
      let count = 0;
        this.assetsArr2.item.map(v => {
          if (v.l3 == undefined){
            v.l3 = 0
          }
          count += v.l3*1
        })
      return Number(count);
    },
    sumAmount2() {
      let count2 = 0;
      this.assetsArr2.item.map(v => {
        if (v.l4 == undefined){
          v.l4 = 0
        }
        count2 += v.l4*1
      })
      return Number(count2);
    },
    sumAmount3() {
      let count3 = 0;
      this.assetsArr2.item.map(v => {
        if (v.l5 == undefined){
          v.l5 = 0
        }
        count3 += v.l5*1
      })
      return Number(count3);
    },
  },
  data(){
    return{
      assetsArr:{item:[{}]},
      assetsArr2:{item:[{}]},
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
    }
  },
  created() {
    // 获取窗口高度 
    this.contentStyleObj=this.$getHeight(167)
    // this.getList()
  },
  methods:{
    changeList(){

    },
    getList(){
      this.getListFb14()
      this.getListFb15()
    },
    getListFb14(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb14',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info.item && res.data.data.info.item.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }else if(res.data.data.info){
            this.assetsArr = res.data.data.info
            this.assetsArr.item = [{}]
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb14'}).then(res=>{
          if(res.data.data.info.item && res.data.data.info.item.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }else if(res.data.data.info){
            this.assetsArr = res.data.data.info
            this.assetsArr.item = [{}]
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
      console.log(this.assetsArr);

    },
    getListFb15(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb15',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info.item && res.data.data.info.item.length != 0){
            this.assetsArr2 = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }else if(res.data.data.info){
            this.assetsArr2 = res.data.data.info
            this.assetsArr2.item = [{}]
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb15'}).then(res=>{
          if(res.data.data.info.item && res.data.data.info.item.length != 0){
            this.assetsArr2 = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }else if(res.data.data.info){
            this.assetsArr2 = res.data.data.info
            this.assetsArr2.item = [{}]
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb14(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb14',
          items:[this.assetsArr],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb14',
          items:[this.assetsArr],
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    saveFb15(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb15',
          items:[this.assetsAr2],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb15',
          items:[this.assetsArr2],
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
        l6: '', 
        l7: '' ,
        l8: '' ,
        l9: '' ,
        l10: '', 
        l11: '', 
        l12: '', 
        l13: '', 
        l14: '', 
        l15: '' ,
        l16: '' ,
      });
    },
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    handleAddItem2(item, index) {
      item.splice(index + 1, 0, {
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
      });
    },
    handleDelItem2(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  width:96%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 4em;
}
.left_big_indent{
  text-indent: 8em;
}
.border_none .el-select{
  width: 100% !important;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #fff ;
}
.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 13px !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.app-container{
  position: relative;
  .right_btns{
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 99;
  }
}
.content{
  // width: 2000px;
  width: 96%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    // line-height: 28px;
    // padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right: 12px;
  }
}
.content2{
  // width: 2000px;
  width: 96%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right:20px;
  }
}


.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -4.15em;
    fill: currentColor;
    // position: relative;
    // left: 12px;
    // top: 105px;
    font-size: 16px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -4.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .add2 {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -4.15em;
    fill: currentColor;
    // position: relative;
    // left: 12px;
    // top: 33px;
    font-size: 16px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -4.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.position_tr{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    // right:5px;
    // top:0;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    // padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}

</style>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
</style>