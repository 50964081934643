<template>
  <!-- 一般纳税人增值税及附加税费申报表-->
  <div>
    <div class="big_box4"> 
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">
            纳税人名称：
            <span>{{ comName }}</span>
          </h6>
          <h6 class="center">
            税款所属期：
            <span>{{ this.durationOfTaxTime }}</span>
          </h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>
              纳税人识别号：
              <span>{{ taxNo }}</span>
            </p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td rowspan="2" colspan="2" class="center weight" style="width:32%">项目</td>
          <td rowspan="2" class="center weight" style="width:16%">栏次</td>
          <td colspan="2" class="center weight" style="width:26%">一般项目</td>
          <td colspan="2" class="center weight" style="width:26%">即征即退项目</td>
        </tr>
        <tr class="top_bg">
          <td class="center" style="width:13%">本月数</td>
          <td class="center" style="width:13%">本年累计</td>
          <td class="center" style="width:13%">本月数</td>
          <td class="center" style="width:13%">本年累计</td>
        </tr>
      </table>
        <!-- 销售额 -->
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">

       
        <table class="content content_body" cellspacing="0">
        <tr>
          <td rowspan="10" style="width:5%" class="bg_color">销售额</td>
          <td style="width:27%" class="bg_color">（一）按适用税率计税销售额</td>
          <td style="width:16%" class="bg_color">1</td>
          <qzf-str-td style="width:13%" :disabled="false" v-model:amount="list[0].asysljsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:13%" :disabled="true" v-model:amount="list[1].asysljsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:13%" :disabled="false" v-model:amount="list[2].asysljsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:13%" :disabled="true" v-model:amount="list[3].asysljsxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">其中：应税货物销售额</td>
          <td class="bg_color">2</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].yshwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].yshwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].yshwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].yshwxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k bg_color">应税劳务销售额</td>
          <td class="bg_color">3</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].yslwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].yslwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].yslwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].yslwxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k bg_color">纳税检查调整的销售额</td>
          <td class="bg_color">4</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].syslNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].syslNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].syslNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].syslNsjctzxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">（二）按简易办法计税销售额</td>
          <td class="bg_color">5</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].ajybfjsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].ajybfjsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].ajybfjsxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].ajybfjsxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">其中：纳税检查调整的销售额</td>
          <td class="bg_color">6</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jybfNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jybfNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].jybfNsjctzxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].jybfNsjctzxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">（三）免、抵、退办法出口销售额</td>
          <td class="bg_color">7</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].mdtbfckxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].mdtbfckxse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">（四）免税销售额</td>
          <td class="bg_color">8</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].msxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].msxse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">其中：免税货物销售额</td>
          <td class="bg_color">9</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].mshwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].mshwxse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="left_k bg_color">免税劳务销售额</td>
          <td class="bg_color">10</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].mslwxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].mslwxse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <!-- 税款计算 -->
        <tr>
          <td rowspan="14" class="bg_color">税款计算</td>
          <td class="bg_color">销项税款</td>
          <td class="bg_color">11</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].xxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].xxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].xxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].xxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">进项税款</td>
          <td class="bg_color">12</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].jxse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].jxse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">上期留抵税额</td>
          <td class="bg_color">13</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].sqldse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].sqldse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">进项税额转出</td>
          <td class="bg_color">14</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jxsezc" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jxsezc" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].jxsezc" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].jxsezc" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">免、抵、退应退税额</td>
          <td class="bg_color">15</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].mdtytse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].mdtytse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">按适用税率计算的纳税检查应补缴税额</td>
          <td class="bg_color">16</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].syslNsjcybjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].syslNsjcybjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">应抵扣税额合计</td>
          <td class="bg_color">17=12+13-14-15+16</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].ydksehj" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].ydksehj" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">实际抵扣税额</td>
          <td class="bg_color">18(如17＜11,则为17,否则为11)</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].sjdkse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].sjdkse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].sjdkse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].sjdkse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">应纳税额</td>
          <td class="bg_color">19=11-18</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].ynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].ynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].ynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].ynse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">期末留抵税额</td>
          <td class="bg_color">20=17-18</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].qmldse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].qmldse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">简易计税办法计算的应纳税额</td>
          <td class="bg_color">21</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jybfYnse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jybfYnse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].jybfYnse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].jybfYnse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">按简易计税办法计算的纳税检查应补缴税额</td>
          <td class="bg_color">22</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jybfNsjcybjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jybfNsjcybjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">应纳税额减征额</td>
          <td class="bg_color">23</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].ynsejze" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].ynsejze" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].ynsejze" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].ynsejze" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">应纳税额合计</td>
          <td class="bg_color">24=19+21-23</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].ynsehj" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].ynsehj" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].ynsehj" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].ynsehj" @change="changeList()"></qzf-str-td>
        </tr>
        <!-- 税款缴纳 -->
        <tr>
          <td rowspan="14" class="bg_color">税款缴纳</td>
          <td class="bg_color">期初未缴税额（多缴为负数）</td>
          <td class="bg_color">25</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].qcwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].qcwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].qcwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].qcwjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">实收出口开具专用缴款书退税额</td>
          <td class="bg_color">26</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].ssckkjzyjkstse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].ssckkjzyjkstse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">本期已缴税额</td>
          <td class="bg_color">27=28+29+30+31</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqyjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].bqyjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].bqyjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].bqyjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">①分次预缴税额</td>
          <td class="bg_color">28</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].fcyjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].fcyjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">②出口开具专用缴款书预缴税额</td>
          <td class="bg_color">29</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].ckkjzyjksyjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">③本期缴纳上期应纳税额</td>
          <td class="bg_color">30</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].bqjnsqynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].bqjnsqynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].bqjnsqynse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].bqjnsqynse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">④本期缴纳欠缴税额</td>
          <td class="bg_color">31</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].bqjnqjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].bqjnqjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].bqjnqjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].bqjnqjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">期末未缴税额（多缴为负数）</td>
          <td class="bg_color">32=24+25+26-27</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].qmwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].qmwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].qmwjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].qmwjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">其中：欠缴税额（≥0）</td>
          <td class="bg_color">33=25+26-27</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].qmwjseQjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].qmwjseQjse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">本期应补(退)税额</td>
          <td class="bg_color">34＝24-28-29</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].bqybtse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">即征即退实际退税额</td>
          <td class="bg_color">35</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].jzjtsjtse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[3].jzjtsjtse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">期初未缴查补税额</td>
          <td class="bg_color">36</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].qcwjcbse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].qcwjcbse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">本期入库查补税额</td>
          <td class="bg_color">37</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].bqrkcbse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].bqrkcbse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">期末未缴查补税额</td>
          <td class="bg_color">38=16+22+36-37</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].qmwjcbse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].qmwjcbse" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <!-- 附加税费 -->
        <tr>
          <td rowspan="3" class="bg_color">附加税费</td>
          <td class="bg_color">城市维护建设税本期应补（退）税额</td>
          <td class="bg_color">39</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].cjs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].cjs" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">教育费附加本期应补（退）费额</td>
          <td class="bg_color">40</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].jyf" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].jyf" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="bg_color">地方教育附加本期应补（退）费额</td>
          <td class="bg_color">41</td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].dfjyf" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="true" v-model:amount="list[1].dfjyf" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>

    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <div class="total_style">
        合计
        <el-tooltip content="本项=增值税+城市维护建设税+教育费附加+地方教育附加" placement="top">
          <i class="iconfont icon-gantanhao1"></i>
        </el-tooltip>
        <span>{{ totalAmount }}</span>元（{{$saveBig(totalAmount)}}）
      </div>
      <el-button @click="saveMain" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveMain" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'mainTable',
  props: {
    title: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  computed:{
    totalAmount(){
      let sum = 0
      sum = Number(this.list[0].bqybtse) + Number(this.list[0].cjs) + Number(this.list[0].jyf) + Number(this.list[0].dfjyf)
      return sum?.toFixed(2)
    },
  },
  data() {
    return {
      expStatus: true,
      list: [{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
    }
  },
  created() {
    // 获取窗口高度 
    this.contentStyleObj=this.$getHeight(380)
    this.getList()
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      // 17 应抵扣税额合计
      this.list[0].ydksehj = String((this.list[0].jxse*1 + this.list[0].sqldse*1 - this.list[0].jxsezc*1 - this.list[0].mdtytse*1 + this.list[0].syslNsjcybjse*1).toFixed(2)*1)
      // 18 实际抵扣税额
      this.list[0].sjdkse = this.list[0].ydksehj*1 < this.list[0].xxse*1?this.list[0].ydksehj:this.list[0].xxse
      // 19 应纳税额
      this.list[0].ynse = String((this.list[0].xxse*1 - this.list[0].sjdkse*1).toFixed(2)*1)
      // 20 期末留抵税额
      this.list[0].qmldse = String((this.list[0].ydksehj*1 - this.list[0].sjdkse*1).toFixed(2)*1)
      // 24 应纳税额合计
      this.list[0].ynsehj = String((this.list[0].ynse*1 + this.list[0].jybfYnse*1 - this.list[0].ynsejze*1).toFixed(2)*1)
      // 27 本期已缴税额
      this.list[0].bqyjse = String((this.list[0].fcyjse*1 + this.list[0].ckkjzyjksyjse*1 + this.list[0].bqjnsqynse*1 + this.list[0].bqjnqjse*1).toFixed(2)*1)
      // 32 期末未缴税额（多缴为负数）
      this.list[0].qmwjse = String((this.list[0].ynsehj*1 + this.list[0].qcwjse*1 + this.list[0].ssckkjzyjkstse*1 - this.list[0].bqyjse*1).toFixed(2)*1)
      // 33 其中：欠缴税额（≥0）
      this.list[0].qmwjseQjse = String((this.list[0].qcwjse*1 + this.list[0].ssckkjzyjkstse*1 - this.list[0].bqyjse*1).toFixed(2)*1)
      if(this.list[0].qmwjseQjse < 0){
        this.list[0].qmwjseQjse = ""
      }
      // 34 本期应补(退)税额
      this.list[0].bqybtse = String((this.list[0].ynsehj*1 - this.list[0].fcyjse*1 - this.list[0].ckkjzyjksyjse*1).toFixed(2)*1)
      // 38 本期应补(退)税额
      this.list[0].qmwjcbse = String((this.list[0].syslNsjcybjse*1 + this.list[0].jybfNsjcybjse*1 + this.list[0].qcwjcbse*1 - this.list[0].bqrkcbse*1).toFixed(2)*1)


      // 本年累计数
      this.list[1].asysljsxse = String((this.bfList[1].asysljsxse*1 - this.bfList[0].asysljsxse*1 + this.list[0].asysljsxse*1).toFixed(2)*1)// 1
      this.list[1].yshwxse = String((this.bfList[1].yshwxse*1 - this.bfList[0].yshwxse*1 + this.list[0].yshwxse*1).toFixed(2)*1)// 2
      this.list[1].yslwxse = String((this.bfList[1].yslwxse*1 - this.bfList[0].yslwxse*1 + this.list[0].yslwxse*1).toFixed(2)*1)// 3
      this.list[1].syslNsjctzxse = String((this.bfList[1].syslNsjctzxse*1 - this.bfList[0].syslNsjctzxse*1 + this.list[0].syslNsjctzxse*1).toFixed(2)*1)// 4
      this.list[1].ajybfjsxse = String((this.bfList[1].ajybfjsxse*1 - this.bfList[0].ajybfjsxse*1 + this.list[0].ajybfjsxse*1).toFixed(2)*1)// 5
      this.list[1].jybfNsjctzxse = String((this.bfList[1].jybfNsjctzxse*1 - this.bfList[0].jybfNsjctzxse*1 + this.list[0].jybfNsjctzxse*1).toFixed(2)*1)// 6
      this.list[1].mdtbfckxse = String((this.bfList[1].mdtbfckxse*1 - this.bfList[0].mdtbfckxse*1 + this.list[0].mdtbfckxse*1).toFixed(2)*1)// 7
      this.list[1].msxse = String((this.bfList[1].msxse*1 - this.bfList[0].msxse*1 + this.list[0].msxse*1).toFixed(2)*1)// 8
      this.list[1].mshwxse = String((this.bfList[1].mshwxse*1 - this.bfList[0].mshwxse*1 + this.list[0].mshwxse*1).toFixed(2)*1)// 9
      this.list[1].mslwxse = String((this.bfList[1].mslwxse*1 - this.bfList[0].mslwxse*1 + this.list[0].mslwxse*1).toFixed(2)*1)// 10
      this.list[1].xxse = String((this.bfList[1].xxse*1 - this.bfList[0].xxse*1 + this.list[0].xxse*1).toFixed(2)*1)// 11
      this.list[1].jxse = String((this.bfList[1].jxse*1 - this.bfList[0].jxse*1 + this.list[0].jxse*1).toFixed(2)*1)// 12
      this.list[1].jxsezc = String((this.bfList[1].jxsezc*1 - this.bfList[0].jxsezc*1 + this.list[0].jxsezc*1).toFixed(2)*1)// 14
      this.list[1].mdtytse = String((this.bfList[1].mdtytse*1 - this.bfList[0].mdtytse*1 + this.list[0].mdtytse*1).toFixed(2)*1)// 15
      this.list[1].syslNsjcybjse = String((this.bfList[1].syslNsjcybjse*1 - this.bfList[0].syslNsjcybjse*1 + this.list[0].syslNsjcybjse*1).toFixed(2)*1)// 16
      this.list[1].sjdkse = String((this.bfList[1].sjdkse*1 - this.bfList[0].sjdkse*1 + this.list[0].sjdkse*1).toFixed(2)*1)// 18
      this.list[1].ynse = String((this.bfList[1].ynse*1 - this.bfList[0].ynse*1 + this.list[0].ynse*1).toFixed(2)*1)// 19
      this.list[1].jybfYnse = String((this.bfList[1].jybfYnse*1 - this.bfList[0].jybfYnse*1 + this.list[0].jybfYnse*1).toFixed(2)*1)// 21

      this.list[1].jybfNsjcybjse = String((this.bfList[1].jybfNsjcybjse*1 - this.bfList[0].jybfNsjcybjse*1 + this.list[0].jybfNsjcybjse*1).toFixed(2)*1)// 22
      this.list[1].ynsejze = String((this.bfList[1].ynsejze*1 - this.bfList[0].ynsejze*1 + this.list[0].ynsejze*1).toFixed(2)*1)// 23
      this.list[1].ynsehj = String((this.bfList[1].ynsehj*1 - this.bfList[0].ynsehj*1 + this.list[0].ynsehj*1).toFixed(2)*1)// 24
      this.list[1].qcwjse = String((this.bfList[1].qcwjse*1 - this.bfList[0].qcwjse*1 + this.list[0].qcwjse*1).toFixed(2)*1)// 25
      this.list[1].ssckkjzyjkstse = String((this.bfList[1].ssckkjzyjkstse*1 - this.bfList[0].ssckkjzyjkstse*1 + this.list[0].ssckkjzyjkstse*1).toFixed(2)*1)// 26
      this.list[1].bqyjse = String((this.bfList[1].bqyjse*1 - this.bfList[0].bqyjse*1 + this.list[0].bqyjse*1).toFixed(2)*1)// 27
      this.list[1].bqjnsqynse = String((this.bfList[1].bqjnsqynse*1 - this.bfList[0].bqjnsqynse*1 + this.list[0].bqjnsqynse*1).toFixed(2)*1)// 30
      this.list[1].bqjnqjse = String((this.bfList[1].bqjnqjse*1 - this.bfList[0].bqjnqjse*1 + this.list[0].bqjnqjse*1).toFixed(2)*1)// 31
      this.list[1].qmwjse = String((this.bfList[1].qmwjse*1 - this.bfList[0].qmwjse*1 + this.list[0].qmwjse*1).toFixed(2)*1)// 32

      this.list[1].qcwjcbse = String((this.list[0].qcwjcbse*1).toFixed(2)*1)// 36
      this.list[1].bqrkcbse = String((this.bfList[1].bqrkcbse*1 - this.bfList[0].bqrkcbse*1 + this.list[0].bqrkcbse*1).toFixed(2)*1)// 37
      this.list[1].qmwjcbse = String((this.list[0].qmwjcbse*1).toFixed(2)*1)// 38

      this.list[1].cjs = String((this.bfList[1].cjs*1 - this.bfList[0].cjs*1 + this.list[0].cjs*1).toFixed(2)*1)// 39
      this.list[1].jyf = String((this.bfList[1].jyf*1 - this.bfList[0].jyf*1 + this.list[0].jyf*1).toFixed(2)*1)// 40
      this.list[1].dfjyf = String((this.bfList[1].dfjyf*1 - this.bfList[0].dfjyf*1 + this.list[0].dfjyf*1).toFixed(2)*1)// 41
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'main',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
            this.bfList = this.$qzfCopy(this.list)
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'main'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
            this.bfList = this.$qzfCopy(this.list)
          }
        })
      }
    },
    saveMain(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          itemName:'main',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'main',
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_style{
  font-size: 14px;
  cursor: pointer;
  span{
    color: var(--themeColor,#17a2b8);
    margin-right: 3px;
  }
  .icon-gantanhao1{
    font-size: 14px;
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
 // margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 15px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 13px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 3px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 0;
    h6{
      float: left;
      width: 33.33%;
      font-size: 13px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
i{
  font-size: 13px;
  margin-right: 3px;
}
</style>
