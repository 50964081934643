<template>
  <el-scrollbar :style="{height:contentStyleObj}">

  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0">
      <tr>
        <td colspan="3" class="center weight" style="background:#eee">计税依据修改原因</td>
        <td colspan="4" class="center">
          <el-select @change="changeJsyj" v-model="infoFjs.jsyj" placeholder="请选择" size="small" filterable style="width:100%" clearable>
              <el-option
                v-for="item in jsyjOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </td>
        <td colspan="4" class="center weight" style="background:#eee">其他修改原因</td>
        <td colspan="4" class="center weight">
          <el-input size="small" v-model="infoFjs.qtxgyy"></el-input>
        </td>
      </tr>
      <tr class="top_bg">
        <td rowspan="3" colspan="2" class="center weight" style="width:12%;">税（费）种</td>
        <td colspan="3" class="center weight">计税（费）依据</td>
        <td rowspan="2" class="center weight">税（费）率</td>
        <td rowspan="2" class="center weight">本期应纳税（费）额</td>
        <td colspan="2" class="center weight">本期减免税（费）额</td>
        <td colspan="2" class="center weight">小微企业"六税两费"减免政策</td>
        <td colspan="2" class="center weight">试点建设培育产教融合型企业</td>
        <td rowspan="2" class="center weight">本期已缴纳税（费）额</td>
        <td rowspan="2" class="center weight">本期应补（退）税（费）额</td>
      </tr>

      <tr class="top_bg">
        <td class="center weight">增值税税额</td>
        <td class="center weight">增值税免抵税额</td>
        <td class="center weight">留抵退税本期扣除额</td>
        <td class="center weight">减免性质代码</td>
        <td class="center weight">减免税（费）额</td>
        <td class="center weight" >减征比例(小数)</td>
        <td class="center weight" >减征额</td>
        <td class="center weight">减免性质代码</td>
        <td class="center weight">本期抵免金额</td>
      </tr>
      <tr class="top_bg">
        <td class="center">1</td>
        <td class="center">2</td>
        <td class="center">3</td>
        <td class="center">4</td>
        <td class="center">5=(1+2-3)×4</td>
        <td class="center">6</td>
        <td class="center">7</td>
        <td class="center">8</td>
        <td class="center">9=(5-7)×8</td>
        <td class="center">10</td>
        <td class="center">11</td>
        <td class="center">12</td>
        <td class="center">13=5-7-9-11-12</td>
      </tr>

      <tr>
        <td class="center bg_color">{{listFjs[0].zsxm_dm}}</td>
        <td class="center bg_color">1</td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].zzsse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].zzsmdse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].ldtsbqkce" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].sl" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:6%" :disabled="expStatus" type="text" v-model:amount="listFjs[0].bqynse" @change="changeList()"></qzf-str-td>
        <td style="width:6%">
          <jmxzdm type="gs_fjs_cjs" v-model:id="listFjs[0].jmxzdmId"></jmxzdm>
        </td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].jmse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="listFjs[0].jzbl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="listFjs[0].jze" @change="changeList()"></qzf-str-td>
        <td style="width:6%" class="center">----</td>
        <td style="width:6%" class="center">----</td>
        <qzf-str-td style="width:6%" :disabled="false" type="text" v-model:amount="listFjs[0].bqyjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:6%" :disabled="expStatus" type="text" v-model:amount="listFjs[0].bqybtse" @change="changeList()"></qzf-str-td>
      </tr>
      <tr class="border_none_tr">
        <td class="center bg_color">{{listFjs[1].zsxm_dm}}</td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].zzsse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].zzsmdse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].ldtsbqkce" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].sl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="listFjs[1].bqynse" @change="changeList()"></qzf-str-td>
        <td>
          <jmxzdm type="gs_fjs_jyffj" v-model:id="listFjs[1].jmxzdmId"></jmxzdm>
        </td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].jmse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="listFjs[1].jzbl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="listFjs[1].jze" @change="changeList()"></qzf-str-td>
        <td>
          <!-- <jmxzdm type="gs_fjs_jyffj" v-model:id="listFjs[1].jmxzdmId"></jmxzdm> -->
        </td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].bqdmje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[1].bqyjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="listFjs[1].bqybtse" @change="changeList()"></qzf-str-td>
      </tr>
      <tr class="border_none_tr">
        <td class="center bg_color">{{listFjs[2].zsxm_dm}}</td>
        <td class="center bg_color">3</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].zzsse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].zzsmdse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].ldtsbqkce" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].sl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="listFjs[2].bqynse" @change="changeList()"></qzf-str-td>
        <td>
          <jmxzdm type="gs_fjs_dfjyffj" v-model:id="listFjs[2].jmxzdmId"></jmxzdm>
        </td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].jmse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="listFjs[2].jzbl" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="listFjs[2].jze" @change="changeList()"></qzf-str-td>
        <td>
          <!-- <jmxzdm type="gs_fjs_dfjyffj" v-model:id="listFjs[2].jmxzdmId"></jmxzdm> -->
        </td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].bqdmje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="listFjs[2].bqyjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="listFjs[2].bqybtse" @change="changeList()"></qzf-str-td>
      </tr>

      <tr>
        <td class="center bg_color">合计</td>
        <td class="center bg_color">4</td>
        <td class="center">----</td>
        <td class="center">----</td>
        <td class="center">----</td>
        <td class="center">----</td>
        <td>{{sum1}}</td>
        <td class="center">----</td>
        <td>{{sum2}}</td>
        <td class="center">----</td>
        <td>{{sum6}}</td>
        <td class="center">----</td>
        <td>{{sum3}}</td>
        <td>{{sum4}}</td>
        <td>{{sum5}}</td>
      </tr>
      <tr>
        <td rowspan="3" colspan="5" class="center bg_color">本期是否适用试点建设培育产教融合型企业抵免政策</td>
        <td class="center" rowspan="3">
          <el-radio v-model="infoFjs.zc" :label="1">是</el-radio>
          <el-radio v-model="infoFjs.zc" :label="0">否</el-radio>
        </td>
        <td class="center bg_color" colspan="6">当期新增投资额</td>
        <td class="center bg_color">5</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a1" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color" colspan="6">上期留抵可抵免税额</td>
        <td class="center bg_color">6</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a2" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color" colspan="6">结转下期可抵免金额</td>
        <td class="center bg_color">7</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a3" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td rowspan="3" colspan="6" class="center bg_color">可用于扣除的增值税留抵退税额使用情况</td>
        <td class="center bg_color" colspan="6">当期新增可用于扣除的留抵退税额</td>
        <td class="center bg_color">8</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a4" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color" colspan="6">上期结存可用于扣除的留抵退税额</td>
        <td class="center bg_color">9</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a5" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color" colspan="6">结转下期可用于扣除的留抵退税额</td>
        <td class="center bg_color">10</td>
        <qzf-str-td colspan="2" :disabled="false" type="text" v-model:amount="infoFjs.a6" @change="changeList()"></qzf-str-td>
      </tr>

    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb5" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
  </el-scrollbar>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb5',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    source:{
      default:'',
      type:String
    },
  },
  computed: {
    sum1(){
      let sum
      sum = Number(this.listFjs[0].bqynse) + Number(this.listFjs[1].bqynse) + Number(this.listFjs[2].bqynse)
      return sum.toFixed(2)
    },
    sum2(){
      let sum
      sum = Number(this.listFjs[0].jmse) + Number(this.listFjs[1].jmse) + Number(this.listFjs[2].jmse)
      return sum.toFixed(2)
    },
    sum3(){
      let sum
      sum = Number(this.listFjs[0].bqdmje) + Number(this.listFjs[1].bqdmje) + Number(this.listFjs[2].bqdmje)
      return sum.toFixed(2)
    },
    sum4(){
      let sum
      sum = Number(this.listFjs[0].bqyjse) + Number(this.listFjs[1].bqyjse) + Number(this.listFjs[2].bqyjse)
      return sum.toFixed(2)
    },
    sum5(){
      let sum
      sum = Number(this.listFjs[0].bqybtse) + Number(this.listFjs[1].bqybtse) + Number(this.listFjs[2].bqybtse)
      return sum.toFixed(2)
    },
    sum6(){
      let sum
      sum = Number(this.listFjs[0].jze) + Number(this.listFjs[1].jze) + Number(this.listFjs[2].jze)
      return sum.toFixed(2)
    },
  },
  data() {
    return {
      expStatus: true,
      listFjs:[{},{},{}],
      infoFjs:{ 
        zc:0,
        jsyj:'',
        qtxgyy:''
      },
      jsyjOption:[
        {
          value:'1|本期行政区划调整，出现适用2档城建税税率情况',
          label:'1|本期行政区划调整，出现适用2档城建税税率情况'
        },
        {
          value:'2|经营业务特殊，适用2档以上城建税税率',
          label:'2|经营业务特殊，适用2档以上城建税税率'
        },
        {
          value:'3|其他',
          label:'3|其他' 
        }
      ],
      taxNo: '',
      contentStyleObj:{}, //高度变化
      durationOfTaxTime:'',
      comName:this.$store.getters['user/comInfo'].comName,
    }
  },
  created() {
    // 获取窗口高度 
    this.contentStyleObj=this.$getHeight(167)
  },
  methods:{
    changeList(){
      if(!this.expStatus){
        return
      }
      this.listFjs.map(v=>{
        v.bqynse = String(((v.zzsse*1 + v.zzsmdse*1 - v.ldtsbqkce*1) * v.sl).toFixed(2))
        // v.jze = String(((((v.zzsse*1 + v.zzsmdse*1 - v.ldtsbqkce*1) * v.sl)*1 - v.jmse*1).toFixed2()* v.jzbl*1).toFixed(2))
        // 又是精度问题 四舍五入的
        v.jze = String(Math.round(parseFloat((v.zzsse*1 + v.zzsmdse*1 - v.ldtsbqkce*1) * v.sl*0.5)*100)/100)
        v.bqybtse = String((v.bqynse*1 - v.jmse*1 - v.jze*1 - v.bqdmje*1 - v.bqyjse*1).toFixed(2))
      })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb5',
          comId:this.comId
        }
        let paramQt = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb5Qt',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
          paramQt.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.msg == 'success'){
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.listFjs = res.data.data.info && res.data.data.info.length != 0 ?res.data.data.info: [{},{},{}]
          }
        })
        gsInfo(paramQt).then(res=>{
          if(res.data.data.info){
            this.infoFjs = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb5'}).then(res=>{
          if(res.data.msg == 'success'){
            this.listFjs = res.data.data.info ?res.data.data.info: [{},{},{}]
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
        gsInfo({tableName: 'gs_vat',itemName:'fb5Qt'}).then(res=>{
          if(res.data.data.info){
            this.infoFjs = res.data.data.info
          }
        })
      }
    },
    saveFb5(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb5',
          items:this.listFjs,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        let param2 = {
          tableName:'gs_vat',
          itemName:'fb5Qt',
          items:[this.infoFjs],
          period:this.startAccountPeriod,
          comId:this.comId
        }
        param.init = this.source ? 'init' : ''
        param2.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            gsEdit(param2).then(res=>{
              if(res.data.msg == "success"){
                this.$qzfMessage("保存成功")
                this.getList()
              }
            })
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb5',
          items:this.listFjs
        }
        let param2 = {
          tableName:'gs_vat',
          itemName:'fb5Qt',
          items:[this.infoFjs]
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            gsEdit(param2).then(res=>{
              if(res.data.msg == "success"){
                this.$qzfMessage("保存成功")
                this.getList()
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  //padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
</style>