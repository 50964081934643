<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td class="center weight" style="width:15%">抵扣项目名称</td>
        <td class="center weight" style="width:25%">份数</td>
        <td class="center weight" style="width:25%">金额</td>
        <td class="center weight" style="width:35%">税额</td>
      </tr>
      <tr>
        <td style="width:15%" class="center bg_color">桥、闸通行费</td>
        <qzf-td style="width:25%" type="text" v-model:amount="qtList.l1" class="right"></qzf-td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l2" class="right"></qzf-str-td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l3" class="right"></qzf-str-td>
      </tr>
      <tr>
        <td style="width:15%" class="center bg_color">国内旅客运输服务</td>
        <qzf-td style="width:25%" type="text" v-model:amount="qtList.l4" class="right"></qzf-td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l5" class="right"></qzf-str-td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l6" class="right"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color">尚未抵扣完毕的不动产或者不动产在建工程</td>
        <td class="center">--</td>
        <td class="center">--</td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l7" class="right"></qzf-str-td>
      </tr>
      <tr>
        <td class="center bg_color">固定资产、无形资产、不动产转变用途可以抵扣的进项税额</td>
        <td class="center">--</td>
        <td class="center">--</td>
        <qzf-str-td style="width:25%" type="text" v-model:amount="qtList.l8" class="right"></qzf-str-td>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb10" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'otherTable',
  props: {
    title:String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      qtList:{},
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(400)
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb10',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.qtList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb10'}).then(res=>{
          if(res.data.data.info){
            this.qtList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb10(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb10',
          items:[this.qtList],
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb10',
          items:[this.qtList]
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
 // padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
</style>