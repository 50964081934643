<template>
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0">
      <tr class="top_bg center">
        <td colspan="8">一、减税项目</td>
      </tr>
      <tr class="top_bg center">
        <td style="width:22%" rowspan="2"  colspan="2">减税性质代码及名称</td>
        <td style="width:6%" rowspan="2">栏次</td>
        <td style="width:13%">期初余额</td>
        <td style="width:13%">本期发生额</td>
        <td style="width:13%">本期应抵减税额</td>
        <td style="width:13%">本期实际抵减税额</td>
        <td style="width:13%">期末余额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3=1+2</td>
        <td>4≤3</td>
        <td colspan="2">5=3-4</td>
      </tr>

      <tr>
        <td colspan="2" >合计</td>
        <td class="center bg_color">1</td>
        <td v-if="infojmb.length <= 4">{{infojmb[0].qcye}}</td>
        <td v-if="infojmb.length <= 4">{{infojmb[0].bqfse}}</td>
        <td v-if="infojmb.length <= 4">{{infojmb[0].bqydjse}}</td>
        <td v-if="infojmb.length <= 4">{{infojmb[0].bqsjdjse}}</td>
        <td v-if="infojmb.length <= 4">{{infojmb[0].qmye}}</td>

        <td v-if="infojmb.length > 4">{{infojmb[0].qcye*1 +infojmb[4].qcye*1}}</td>
        <td v-if="infojmb.length > 4">{{infojmb[0].bqfse*1 + infojmb[4].bqfse*1}}</td>
        <td v-if="infojmb.length > 4">{{infojmb[0].bqydjse*1 + infojmb[4].bqydjse*1}}</td>
        <td v-if="infojmb.length > 4">{{infojmb[0].bqsjdjse*1 + infojmb[4].bqsjdjse*1}}</td>
        <td v-if="infojmb.length > 4">{{infojmb[0].qmye*1 + infojmb[4].qmye*1}}</td>
      </tr>

      <tr>
        <td colspan="2" >税控盘抵减 
          <jmxzdm type="gs_vat_js" v-model:id="infojmb[0].jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[0].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[0].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="infojmb[0].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[0].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="infojmb[0].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr v-if="infojmb.length > 4">
        <td colspan="2" >
          <jmxzdm type="gs_vat_js" v-model:id="infojmb[4].jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" v-model:amount.sync="infojmb[4].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount.sync="infojmb[4].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount.sync="infojmb[4].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount.sync="infojmb[4].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount.sync="infojmb[4].qmye" @change="changeList()"></qzf-str-td>
      </tr>
    </table>
    <table  class="content" cellspacing="0">
      <tr class="top_bg center">
        <td colspan="9">二、免税项目</td>
      </tr>
      <tr class="top_bg center">
        <td rowspan="2"  colspan="2" style="width: 14%;">免税性质代码及名称</td>
        <td rowspan="2">栏次</td>
        <td>免征增值税项目销售额</td>
        <td>免税销售额扣除项目本期实际扣除金额</td>
        <td>扣除后免税销售额</td>
        <td>免税销售额适用税率</td>
        <td>免税销售额对应的进项税额</td>
        <td>免税额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3=1-2</td>
        <td>--</td>
        <td>4</td>
        <td>5</td>
      </tr>
      <tr>
        <td colspan="2">合计</td>
        <td class="center bg_color">1</td>
        <td>{{infojmb[1].qcye*1 + infojmb[2].qcye*1 + infojmb[3].qcye*1}}</td>
        <td>{{infojmb[1].bqfse*1 + infojmb[2].bqfse*1 + infojmb[3].bqfse*1}}</td>
        <td>{{infojmb[1].bqydjse*1 + infojmb[2].bqydjse*1 + infojmb[3].bqydjse*1}}</td>
        <td>--</td>
        <td>{{infojmb[1].bqsjdjse*1 + infojmb[2].bqsjdjse*1 + infojmb[3].bqsjdjse*1}}</td>
        <td>{{infojmb[1].qmye*1 + infojmb[2].qmye*1 + infojmb[3].qmye*1}}</td>
      </tr>
      <tr>
        <td colspan="2" >出口免税</td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[1].qcye" @change="changeList()"></qzf-str-td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
      </tr>

      <tr class="border_none_tr">
        <td colspan="2" >
          <jmxzdm type="gs_vat_ms" v-model:id="infojmb[2].jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">4</td>
        <qzf-str-td :disabled="false" @change="changeinfojmbxse(infojmb[2])" type="text" v-model:amount="infojmb[2].qcye"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[2].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="infojmb[2].bqydjse" @change="changeList()"></qzf-str-td>
        <td>
          <el-select v-model="infojmb[2].msxsesysl" placeholder="请选择" size="small" clearable style="width:100%">
            <el-option
              v-for="item in optionsSl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <!-- <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[2].msxsesysl" @change="changeList()"></qzf-str-td> -->
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[2].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[2].qmye" @change="changeList()"></qzf-str-td>
      </tr>

      <tr class="border_none_tr">
        <td colspan="2" >
          <jmxzdm type="gs_vat_ms" v-model:id="infojmb[3].jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">5</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[3].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[3].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="infojmb[3].bqydjse" @change="changeList()"></qzf-str-td>
        <td>
          <el-select v-model="infojmb[3].msxsesysl" placeholder="请选择" size="small" clearable style="width:100%">
            <el-option
              v-for="item in optionsSl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <!-- <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[3].msxsesysl" @change="changeList()"></qzf-str-td> -->
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[3].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="infojmb[3].qmye" @change="changeList()"></qzf-str-td>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb6" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'mitigateTable',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      infojmb: [{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      optionsSl:[{
        value: '0.13',
        label: '0.13'
      },{
        value: '0.09',
        label: '0.09'
      },{
        value: '0.06',
        label: '0.06'
      },{
        value: '0.05',
        label: '0.05'
      }],
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(400)
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.infojmb.map(v=>{
        if(v.type == 1 || v.type == 6){
          v.bqydjse = String(v.qcye*1 + v.bqfse*1)
          v.qmye = String(v.bqydjse*1 - v.bqsjdjse*1)
        }else if(v.type == 4){
          v.bqydjse = String(v.qcye*1 - v.bqfse*1)
        }
      })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb6',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.infojmb = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb6'}).then(res=>{
          if(res.data.data.info){
            this.infojmb = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb6(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb6',
          items:this.infojmb,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb6',
          items:this.infojmb
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  //padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
</style>