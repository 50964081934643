<template>
  <!-- 一般纳税人增值税附表2-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <div class="fixed_box_table" :style="{height:contentStyleObj,marginTop:'10px'}">
        <table class="content" cellspacing="0" width="100%" >
          <tr class="top_bg">
            <td colspan="5" class="center weight">一、申报抵扣的进项税额</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:30%">项目</td>
            <td class="center" style="width:10%">栏次</td>
            <td class="center" style="width:20%">份数</td>
            <td class="center" style="width:20%">金额</td>
            <td class="center" style="width:20%">税额</td>
          </tr>
          <tr>
            <td class="bg_color">（一）认证相符的增值税专用发票</td>
            <td class="bg_color">1=2+3</td>
            <td :style="expStatus?{background:'#efefef'}:{background:'none'}">
              <input type="float" :disabled="expStatus" @change="changeList()" v-model="list[0].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[0].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="expStatus" v-model:amount="list[0].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[0].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其中：本期认证相符且本期申报抵扣</td>
            <td class="bg_color">2</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[1].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[1].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[1].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">前期认证相符且本期申报抵扣</td>
            <td class="bg_color">3</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[2].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[2].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[2].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[2].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（二）其他扣税凭证</td>
            <td class="bg_color">4=5+6+7+8</td>
            <td :style="expStatus?{background:'#efefef'}:{background:'none'}">
              <input type="float" :disabled="expStatus" @change="changeList()" v-model="list[3].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[3].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color"> 其中：海关进口增值税专用缴款书</td>
            <td class="bg_color">5</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[4].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[4].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[4].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">农产品收购发票或者销售发票</td>
            <td class="bg_color">6</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[5].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[5].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[5].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[5].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">代扣代缴税收缴款凭证</td>
            <td class="bg_color">7</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[6].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[6].fs" @change="changeList()"></qzf-str-td> -->
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[6].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加计扣除农产品进项税额</td>
            <td class="bg_color">8a</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[36].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其他</td>
            <td class="bg_color">8b</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[7].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[7].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[7].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <!-- <td>（三）本期用于购建不动产的扣税凭证</td> -->
            <td class="bg_color">（三）本期用于购建不动产的扣税凭证</td>
            <td class="bg_color">9</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[8].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[8].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[8].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <!-- <td>（四）本期不动产允许抵扣进项税额</td> -->
            <td class="bg_color">（四）本期用于抵扣的旅客运输服务扣税凭证</td>
            <td class="bg_color">10</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[9].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[9].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[9].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（五）外贸企业进项税额抵扣证明</td>
            <td class="bg_color">11</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">当期申报抵扣进项税额合计</td>
            <!-- <td>12=1+4-9+10+11</td> -->
            <td class="bg_color">12=1+4+11</td>
            <td :style="expStatus?{background:'#efefef'}:{background:'none'}">
              <input type="float" :disabled="expStatus" @change="changeList()" v-model="list[11].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[11].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].se" @change="changeList()"></qzf-str-td>
          </tr>


          <tr class="top_bg">
            <td colspan="5" class="center weight">二、进项税额转出额</td>
          </tr>
          <tr class="top_bg">
            <td class="center">项目</td>
            <td class="center">栏次</td>
            <td colspan="3" class="center">税额</td>
          </tr>
          <tr>
            <td class="bg_color">本期进项税额转出额</td>
            <td class="bg_color">13=14至23之和</td>
            <qzf-str-td :colspan="3" :disabled="expStatus" v-model:amount="list[12].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其中：免税项目用</td>
            <td class="bg_color">14</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[13].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">集体福利、个人消费</td>
            <td class="bg_color">15</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[14].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">非正常损失</td>
            <td class="bg_color">16</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[15].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">简易计税方法征税项目用</td>
            <td class="bg_color">17</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[16].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">免抵退税办法不得抵扣的进项税额</td>
            <td class="bg_color">18</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[17].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">纳税检查调减进项税额</td>
            <td class="bg_color">19</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[18].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">红字专用发票信息表注明的进项税额</td>
            <td class="bg_color">20</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[19].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">上期留抵税额抵减欠税</td>
            <td class="bg_color">21</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[20].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">上期留抵税额退税</td>
            <td class="bg_color">22</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[21].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">异常凭证转出进项税额</td>
            <td class="bg_color">23a</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[37].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其他应作进项税额转出的情形</td>
            <td class="bg_color">23b</td>
            <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[22].se" @change="changeList()"></qzf-str-td>
          </tr>

          <tr class="top_bg">
            <td colspan="5" class="center weight">三、待抵扣进项税额</td>
          </tr>
          <tr class="top_bg">
            <td class="center">项目</td>
            <td class="center">栏次</td>
            <td class="center">份数</td>
            <td class="center">金额</td>
            <td class="center">税额</td>
          </tr>
          <tr>
            <td class="bg_color">（一）认证相符的增值税专用发票</td>
            <td class="bg_color">24</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>
          <tr>
            <td class="left_k bg_color">期初已认证相符但未申报抵扣</td>
            <td class="bg_color">25</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[24].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[24].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[24].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[24].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">本期认证相符且本期未申报抵扣</td>
            <td class="bg_color">26</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[25].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[25].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[25].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[25].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">期末已认证相符但未申报抵扣</td>
            <td class="bg_color">27</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[26].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[26].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[26].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[26].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其中：按照税法规定不允许抵扣</td>
            <td class="bg_color">28</td>
            <td>
              <input type="float" @change="changeList()" v-model="list[27].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[27].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[27].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[27].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（二）其他扣税凭证</td>
            <td class="bg_color">29=30至33之和</td>
            <td :style="expStatus?{background:'#efefef'}:{background:'none'}">
              <input type="float" :disabled="expStatus" @change="changeList()" v-model="list[28].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[28].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="expStatus" v-model:amount="list[28].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[28].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其中：海关进口增值税专用缴款书</td>
            <td class="bg_color">30</td>
            <td>
              <input type="float"  @change="changeList()" v-model="list[29].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[29].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[29].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[29].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">农产品收购发票或者销售发票</td>
            <td class="bg_color">31</td>
            <td>
              <input type="float"  @change="changeList()" v-model="list[30].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[30].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[30].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[30].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">代扣代缴税收缴款凭证</td>
            <td class="bg_color">32</td>
            <td>
              <input type="float"  @change="changeList()" v-model="list[31].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[31].fs" @change="changeList()"></qzf-str-td> -->
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[31].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其他</td>
            <td class="bg_color">33</td>
            <td>
              <input type="float"  @change="changeList()" v-model="list[32].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="false" v-model:amount="list[32].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[32].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[32].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color"></td>
            <td class="bg_color">34</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr class="top_bg">
            <td colspan="5" class="center weight">四、其他</td>
          </tr>
          <tr class="top_bg">
            <td class="center">项目</td>
            <td class="center">栏次</td>
            <td class="center">份数</td>
            <td class="center">金额</td>
            <td class="center">税额</td>
          </tr>
          <tr>
            <td class="bg_color">本期认证相符的增值税专用发票</td>
            <td class="bg_color">35</td>
            <!-- :style="expStatus?{background:'#efefef'}:{background:'none'}" -->
            <td >
              <input type="float" :disabled="false" @change="changeList()" v-model="list[34].fs" style="width: 100%;height: 28px;line-height: 28px;padding:0;color: #333333;outline: none;">
            </td>
            <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[34].fs" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[34].je" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[34].se" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">代扣代缴税额</td>
            <td class="bg_color">36</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[35].se" @change="changeList()"></qzf-str-td>
          </tr>
        </table>
      </div>
      
    </div>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')" style="text-align: right;">
      <el-button @click="saveFb2" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb2',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(327)
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list[0].fs = String(this.list[1].fs*1 + this.list[2].fs*1)
      this.list[0].je = String((this.list[1].je*1 + this.list[2].je*1).toFixed(2))
      this.list[0].se = String((this.list[1].se*1 + this.list[2].se*1).toFixed(2))
      //4 (二）其他扣税凭证
      this.list[3].fs = String(this.list[4].fs*1 + this.list[5].fs*1 + this.list[6].fs*1 + this.list[7].fs*1 + this.list[36].fs*1)
      this.list[3].je = String((this.list[4].je*1 + this.list[5].je*1 + this.list[6].je*1 + this.list[7].je*1 + this.list[36].je*1).toFixed(2))
      this.list[3].se = String((this.list[4].se*1 + this.list[5].se*1 + this.list[6].se*1 + this.list[7].se*1 + this.list[36].se*1).toFixed(2))
      //12 当期申报抵扣进项税额合计
      this.list[11].fs = String(this.list[0].fs*1 + this.list[3].fs*1 + this.list[10].fs*1)
      this.list[11].je = String((this.list[0].je*1 + this.list[3].je*1 + this.list[10].je*1).toFixed(2))
      this.list[11].se = String((this.list[0].se*1 + this.list[3].se*1 + this.list[10].se*1).toFixed(2))
      //13 本期进项税额转出额
      this.list[12].se = String((this.list[13].se*1 + this.list[14].se*1 + this.list[15].se*1 + this.list[16].se*1 + this.list[17].se*1 + this.list[18].se*1 + this.list[19].se*1 + this.list[20].se*1 + this.list[21].se*1 + this.list[22].se*1 + this.list[23].se*1 + this.list[37].se*1).toFixed(2))
      //29 （二）其他扣税凭证
      this.list[28].fs = String(this.list[29].fs*1 + this.list[30].fs*1 + this.list[31].fs*1 + this.list[32].fs*1)
      this.list[28].je = String((this.list[29].je*1 + this.list[30].je*1 + this.list[31].je*1 + this.list[32].je*1).toFixed(2))
      this.list[28].se = String((this.list[29].se*1 + this.list[30].se*1 + this.list[31].se*1 + this.list[32].se*1).toFixed(2))
      // 35 本期认证相符的增值税专用发票
      // this.list[34].fs = String(this.list[11].fs)
      // this.list[34].je = String((this.list[11].je*1).toFixed(2))
      // this.list[34].se = String((this.list[11].se*1).toFixed(2))
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb2',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb2'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb2(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb2',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb2',
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
  input{
    width: 100%;
    line-height: 25px;
    padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right: 12px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
// .top_title3{
//   width: 100%;
//   margin: 0 auto;
//   h4{
//     font-size: 20px;
//     color: #333;
//     font-weight: 600;
//     text-align: center;
//   }
//   h5{
//     font-size: 16px;
//     color: #333;
//     font-weight: normal;
//     text-align: center;
//     margin-top: 10px;
//   }
//   .bottom_font{
//     width: 100%;
//     margin: 0 auto;
//     margin-top: 10px;
//     h6{
//       float: left;
//       width: 33.33%;
//       font-size: 14px;
//       color: #333;
//       font-weight: normal;
//     }
//     h6:nth-child(1){
//       padding-top: 10px;
//     }
//     h6:nth-child(2  ){
//       padding-top: 10px;
//     }
//   }
// }
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}

</style>
