<template>
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0">

      <tr class="top_bg center">
        <td rowspan="3" colspan="2">项目及栏次</td>
        <td rowspan="2">本期服务、不动产和无形资产价税合计额（免税销售额）</td>
        <td colspan="5">服务、不动产和无形资产扣除项目</td>
      </tr>

      <tr class="top_bg center">
        <td>期初余额</td>
        <td>本期发生额</td>
        <td>本期应扣除金额</td>
        <td>本期实际扣除金额</td>
        <td>期末余额</td>
      </tr>


      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3</td>
        <td>4=2+3</td>
        <td>5（5≤1且5≤4）</td>
        <td>6=4-5</td>
      </tr>
      <tr>
        <td style="width:12%" class="bg_color">13%税率的项目</td>
        <td style="width:12%" class="bg_color">1</td>
        <qzf-str-td style="width:12%" :disabled="false" type="text" v-model:amount="list[0].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:12%" :disabled="false" type="text" v-model:amount="list[0].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:12%" :disabled="false" type="text" v-model:amount="list[0].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:12%" :disabled="expStatus" type="text" v-model:amount="list[0].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:12%" :disabled="false" type="text" v-model:amount="list[0].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:12%" :disabled="expStatus" type="text" v-model:amount="list[0].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">9%税率的项目</td>
        <td class="bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[1].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[1].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">6%税率的项目(不含金融商品转让)</td>
        <td class="bg_color">3</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[2].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[2].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">6%税率的金融商品转让项目</td>
        <td class="bg_color">4</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[3].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[3].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">5%税率的项目</td>
        <td class="bg_color">5</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[4].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[4].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">3%税率的项目</td>
        <td class="bg_color">6</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[5].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[5].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">13%免抵退税项目</td>
        <td class="bg_color">7</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[6].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[6].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="bg_color">免税的项目</td>
        <td class="bg_color">8</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[7].msxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[7].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[7].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[7].bqykcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[7].bqsjkcje" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[7].qmye" @change="changeList()"></qzf-str-td>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')" style="text-align: right;">
    <el-button @click="saveFb3" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'fb3',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      list:[{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list.map(v=>{
        v.bqykcje = String((v.qcye*1 + v.bqfse*1).toFixed(2))
        v.qmye = String((v.bqykcje*1 - v.bqsjkcje*1).toFixed(2))
      })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb3',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb3'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb3(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb3',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb3',
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  //padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
// .top_title3{
//   width: 100%;
//   margin: 0 auto;
//   h4{
//     font-size: 20px;
//     color: #333;
//     font-weight: 600;
//     text-align: center;
//   }
//   h5{
//     font-size: 16px;
//     color: #333;
//     font-weight: normal;
//     text-align: center;
//     margin-top: 10px;
//   }
//   .bottom_font{
//     width: 100%;
//     margin: 0 auto;
//     margin-top: 10px;
//     padding: 0 16px;
//     h6{
//       float: left;
//       width: 33.33%;
//       font-size: 14px;
//       color: #333;
//       font-weight: normal;
//     }
//     h6:nth-child(1){
//       padding-top: 10px;
//     }
//     h6:nth-child(2  ){
//       padding-top: 10px;
//     }
   
//   }
// }
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
</style>