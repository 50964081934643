<template>
  <el-scrollbar :style="{height:contentStyleObj}">

    <div class="big_box4">
      <div class="top_title3">
        <h4>成品油购销存明细表</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <div class="cent">
        <div class="add" @click="handleAddItem(assetsArr,index)">
          <i class="iconfont icon-jiahao"></i>
        </div>
        <table class="content" cellspacing="0" width="100%">
          <tr class="top_bg">
            <td rowspan="4" class="center" style="width:10%">油品型号</td>
            <td class="center" colspan="4" style="width:20%">期初库存</td>
            <td class="center" colspan="4" style="width:20%">本期入库</td>
            <td class="center" colspan="6" style="width:30%">本期出库</td>
            <td class="center" colspan="4" style="width:20%">期末库存</td>
          </tr>
          <tr class="top_bg">
            <td class="center" rowspan="2" colspan="2">数量</td>
            <td class="center" rowspan="2" colspan="2">金额</td>

            <td class="center" rowspan="2" colspan="2">数量</td>
            <td class="center" rowspan="2" colspan="2">金额</td>

            <td class="center" colspan="2" >应税部分</td>
            <td class="center" colspan="4" >非应税部分</td>

            <td class="center" rowspan="2" colspan="2" >数量</td>
            <td class="center" rowspan="2" colspan="2" >金额</td>
          </tr>
          <tr class="top_bg">
            <td class="center" rowspan="2">数量</td>
            <td class="center" rowspan="2">金额</td>
            <td class="center" colspan="2">数量</td>
            <td class="center" colspan="2">金额</td>
          </tr>
          <tr class="top_bg">
            <td class="center">自购</td>
            <td class="center">代储</td>
            <td class="center">自购</td>
            <td class="center">代储</td>
            <td class="center">自购</td>
            <td class="center">代储</td>
            <td class="center">自购</td>
            <td class="center">代储</td>
            <td class="center">自用</td>
            <td class="center">代储</td>
            <td class="center">自用</td>
            <td class="center">代储</td>
            <td class="center">自购</td>
            <td class="center">代储</td>
            <td class="center">自购</td>
            <td class="center">代储</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:5%"></td>
            <td class="center" style="width:5%">1</td>
            <td class="center" style="width:5%">2</td>
            <td class="center" style="width:5%">3</td>
            <td class="center" style="width:5%">4</td>
            <td class="center" style="width:5%">5</td>
            <td class="center" style="width:5%">6</td>
            <td class="center" style="width:5%">7</td>
            <td class="center" style="width:5%">8</td>
            <td class="center" style="width:5%">9</td>
            <td class="center" style="width:5%">10</td>
            <td class="center" style="width:5%">11</td>
            <td class="center" style="width:5%">12</td>
            <td class="center" style="width:5%">13</td>
            <td class="center" style="width:5%">14</td>
            <td class="center" style="width:5%">15</td>
            <td class="center" style="width:5%">16</td>
            <td class="center" style="width:5%">17</td>
            <td class="center" style="width:5%">18</td>
          </tr>
          <tr v-for="(item, $index) in assetsArr" :key="$index" class="position_tr">
            <td class="new_input" style="width:5%">
              <el-select @change="changeProduct" v-model="item.type" placeholder="请选择产品名称" size="small" filterable style="width:100%" clearable>
                <el-option
                  v-for="item in productOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l1" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l2" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
            <td style="width:5%" class="center">--</td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l6" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l7" @change="changeList()"></qzf-str-td>
            <td style="width:5%" class="center">--</td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l9" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l10" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l11" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l12" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l13" @change="changeList()"></qzf-str-td>
            <td style="width:5%" class="center">--</td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l15" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l16" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="item.l17" @change="changeList()"></qzf-str-td>
            <td style="width:5%" class="center">--</td>
            <div class="close" @click="handleDelItem(assetsArr,$index)">
              <i class="iconfont icon-shanchu"></i> 
            </div>
          </tr>
          <tr>
            <td class="center" style="width:5%">合计</td>
            <td class="center">--</td>
            <td class="center">--</td>
            <qzf-str-td :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-str-td>
            <td class="center">--</td>
            <td class="center">--</td>
            <td class="center">--</td>
            <qzf-str-td :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-str-td>
            <td class="center">--</td>
            <td class="center">--</td>
            <qzf-str-td :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-str-td>
            <td class="center">--</td>
            <td class="center">--</td>
            <qzf-str-td :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-str-td>
            <td class="center">--</td>
            <td class="center">--</td>
            <td class="center">--</td>
            <qzf-str-td :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-str-td>
            <td class="center">--</td>
          </tr>
        </table>
      </div>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
        <el-button type="primary" @click="saveFb12()" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
        <qzf-button type="primary" @success="saveFb12()" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
      </div>
    </div>

    <div class="big_box4">
      <div class="top_title3">
        <h4>成品油购数量明细表</h4>
      </div>
      <div class="cent">
        <div class="add" @click="handleAddItem2(assetsArr2,index)">
          <i class="iconfont icon-jiahao"></i>
        </div>
        <table class="content" cellspacing="0">
          <tr class="top_bg">
            <td rowspan="2" class="center" style="width:10%">油量型号</td>
            <td class="center" rowspan="2" style="width:20%">期初库存量</td>
            <td class="center" rowspan="2" style="width:20%">本期购进量</td>
            <td class="center" colspan="2" style="width:30%">本期出库量</td>
            <td class="center" rowspan="2" style="width:20%">期末库存量</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:15%">应税销售量</td>
            <td class="center" style="width:15%">应扣除数量</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:10%">---</td>
            <td class="center" style="width:20%">1</td>
            <td class="center" style="width:20%">2</td>
            <td class="center" style="width:15%">3</td>
            <td class="center" style="width:15%">4</td>
            <td class="center" style="width:20%">5=1+2-3-4</td>
          </tr>
          <tr v-for="(item, $index) in assetsArr2" :key="$index" class="position_tr">
            <td class="new_input" style="width:10%">
              <el-select @change="changeProduct" v-model="item.type" placeholder="请选择产品名称" size="small" filterable style="width:100%" clearable>
                <el-option
                  v-for="item in productOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </td>
            <qzf-str-td style="width:20%" :disabled="false" v-model:amount="item.l1" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:20%" :disabled="false" v-model:amount="item.l2" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:15%" :disabled="false" v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:15%" :disabled="false" v-model:amount="item.l4" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:20%" :disabled="false" v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
            <div class="close" @click="handleDelItem2(assetsArr2,$index)">
              <i class="iconfont icon-shanchu"></i> 
            </div>
          </tr>
        </table>
      </div>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
        <el-button type="primary" @click="saveFb13()" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
        <qzf-button type="primary" @success="saveFb13()" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
      </div>
    </div>
  </el-scrollbar>

</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'fb1',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data(){
    return{
      assetsArr:[{},{}],
      assetsArr2:[{},{}],
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
      productOption:[
        {
          value:'400|石脑油',
          label:'400|石脑油'
        },
        {
          value:'500|溶剂油',
          label:'500|溶剂油'
        },
        {
          value:'600|润滑油',
          label:'600|润滑油'
        },
        {
          value:'700|燃料油',
          label:'700|燃料油'
        },
        {
          value:'800|航空煤油',
          label:'800|航空煤油'
        },
        {
          value:'A10|含铅汽油',
          label:'A10|含铅汽油'
        },
        {
          value:'A20|无铅汽油',
          label:'A20|无铅汽油'
        },
        {
          value:'A21|90#无铅汽油',
          label:'A21|90#无铅汽油'
        },
        {
          value:'A22|93#无铅汽油',
          label:'A22|93#无铅汽油'
        },
        {
          value:'A23|97#无铅汽油',
          label:'A23|97#无铅汽油'
        },
        {
          value:'A24|98#无铅汽油',
          label:'A24|98#无铅汽油'
        },
        {
          value:'A25|92#无铅汽油',
          label:'A25|92#无铅汽油'
        },
        {
          value:'A25|95#无铅汽油',
          label:'A25|95#无铅汽油'
        },
        {
          value:'A99|其他无铅汽油',
          label:'A99|其他无铅汽油'
        },
        {
          value:'B11|10#柴油',
          label:'B11|10#柴油'
        },
        {
          value:'B12|5#柴油',
          label:'B12|5#柴油'
        },
        {
          value:'B13|0#柴油',
          label:'B13|0#柴油'
        },
        {
          value:'B21|-10#柴油',
          label:'B21|-10#柴油'
        },
        {
          value:'B22|-20#柴油',
          label:'B22|-20#柴油'
        },
        {
          value:'B23|-35#柴油',
          label:'B23|-35#柴油'
        },
        {
          value:'B24|-50#柴油',
          label:'B24|-50#柴油'
        },
        {
          value:'B25|生物柴油',
          label:'B25|生物柴油'
        },
        {
          value:'B99|其他柴油',
          label:'B99|其他柴油'
        },
      ]
    }
  },
  created() {
    // 获取窗口高度 
    this.contentStyleObj=this.$getHeight(270)
    this.getList()
  },
  methods:{
    changeList(){

    },
    getList(){
      this.getListFb12()
      this.getListFb13()
    },
    getListFb12(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb12',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb12'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
          console.log(this.assetsArr);
        })
      }
    },
    getListFb13(){
      if(this.startAccountPeriod){
        let param2 = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb13',
          comId:this.comId
        }
        if(this.source){
          param2.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param2).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr2 = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb13'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr2 = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb12(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb12',
          items:this.assetsArr,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getListFb12()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb12',
          items:this.assetsArr
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getListFb12()
          }
        })
      }
    },
    saveFb13(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb13',
          items:this.assetsArr2,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getListFb13()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb13',
          items:this.assetsArr2
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getListFb13()
          }
        })
      }
    },
    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        type: "", 
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
        l6: '', 
        l7: '' ,
        l8: '' ,
        l9: '' ,
        l10: '' , 
        l11: '' ,
        l12: '' ,
        l13: '' ,
        l14: ''  ,
        l15: '' , 
        l16: '' ,
        l17: '' ,
        l18: '' 
      });
    },
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    handleAddItem2(item, index) {
      item.splice(index + 1, 0, {
        type: "", 
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
      });
    },
    handleDelItem2(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  width: 95%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 4em;
}
.left_big_indent{
  text-indent: 8em;
}
.border_none .el-select{
  width: 100% !important;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #fff ;
}
.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 13px !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.app-container{
  position: relative;
  .right_btns{
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 99;
  }
}
.content{
  // width: 2000px;
  width: 96%;
  // margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
    padding-right: 12px;
  }
}
.content2{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right:20px;
  }
}


.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -4.15em;
    fill: currentColor;
    // position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -4.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.position_tr{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    // right:5px;
    // top:0;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    // padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.cent {
  display: flex;
}

</style>
